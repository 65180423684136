import _ from '@lodash';
import { useState, useEffect, useRef, useReducer } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  getOrders,
  getOrderStatuses,
  selectOrders,
  setCustomer,
  setSalesPerson,
  setSupplier,
  setOrdersSearchText,
  getCustomersList,
  resetOrders,
  getStatuses,
  getSuppliers,
} from 'app/main/apps/e-commerce/store/ordersSlice';
import { useDispatch, useSelector } from 'react-redux';
import FuseUtils from '@fuse/utils/FuseUtils';
import Confirm from '@fuse/core/FuseDialog/confirm';
import CustomerFilter, { OrderTypeFilter, OrderTyepFilterFunction, IncludeInvoiceFilter, IncludeArchiveFilter, SalesPersonFilter } from 'app/main/apps/e-commerce/shared/CustomerFilter';
import Entities from 'app/main/apps/e-commerce/shared/Entities';
import getColumns from 'app/main/apps/e-commerce/orders/OrdersTableColumns';
import { selectUser } from 'app/store/userSlice';
import { authPermissions } from 'app/auth';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { upperCase } from 'lodash';
import OrderDialog from '../order/Dialogs/OrderDialog';
import ViewOrderDialog from '../../../dashboards/main/dialogs/ViewOrderDialog';
import ReallocationDialog from '../order/Dialogs/ReallocationDialog';
import { isAdminOrStaff, isCustomer, isDefaultSupplierTypes, isSupplier } from '../../common/AuraFunctions';
import downloadFile from '../../common/AuraDownload';
import ChooseSupplier from '../order/Dialogs/ChooseSupplier';
import CreateSupplierOrder from '../order/Dialogs/SupplierShipping';
import getFilterOptions from './filterOptions';
import ProductDialog from '../product/dialogs/ProductDialog';
import ConfirmInvoiceDialog from '../order/Dialogs/ConfirmInvoiceDialog';
import { getSalesPersons } from '../../client/store/customerSlice';

function Orders(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isSupplierInvoice = props && props.for && props.for === 'supplier-invoices';
  const hasPermission = user && FuseUtils.hasPermission(authPermissions.manage, user.role);
  const options = getFilterOptions(props.type, user.role, isSupplierInvoice);
  const [filterType, setSelectedFilterType] = useState(options[0]);
  const [includeInvoice, setIncludeInvoice] = useState(false);
  const [includeArchive, setIncludeArchive] = useState(false);
  const ref = useRef();
  const salesPersonRef = useRef();
  const hasFilter = useSelector(state => state.eCommerceApp.orders.params);
  const { shipmentId } = useParams();

  useEffect(() => {
    return () => {
      // following code will run on unmount.
      dispatch(resetOrders());
    };
  }, []);

  useEffect(() => {
    dispatch(resetOrders());
    if(isAdminOrStaff(user) && (!['shipment', 'container'].includes(props.type)) && props.for !== 'supplier-invoices') {
      dispatch(getCustomersList({ lt: 'n' }));
    }
    if(isAdminOrStaff(user) && (props.for === 'supplier-invoices' || props.type === 'order')) {
      dispatch(getSuppliers());
    }
    if(isAdminOrStaff(user) && ['order', 'quote', 'rfq'].includes(props.type)) {
      dispatch(getSalesPersons({ isFilter: true }));
    }
    // OrderTyepFilterFunction only returning when shipmentId is New so addded condition here
    // Have to check whether OrderTyepFilterFunction is used anywhere in order page
    if(props.type === 'order' && hasPermission && ['customer', 'customer_archive'].includes(filterType?.value) && shipmentId === 'new') {
      dispatch(getStatuses('order_type'));
    }
    if (['order', 'in-production', 'quote', 'rfq', 'fulfilled', 'confirmed'].includes(props.type)) {
      dispatch(getOrderStatuses({params: { c: 'order_type' }}));
    }
    if (!location.search) {
      setSelectedFilterType(options[0])
    }
  },[props.for]);

  useEffect(() => {
    if (location.search) {
      const sp = new URLSearchParams(location.search);
      switch (props.type) {
        case 'order':
          if (sp.has('include_invoice_status')) {
            setSelectedFilterType('include_invoice_status')
            setIncludeInvoice(sp.get('include_invoice_status') === 'true')
          }
          if (sp.has('include_archive')) {
            setSelectedFilterType('include_archive')
            setIncludeArchive(sp.get('include_archive') === 'true')
          }
          break;
        case 'invoice':
          if (sp.has('cid') && sp.has('archive'))
            setSelectedFilterType(options[2])
          else if (sp.has('sid') && sp.has('archive'))
            setSelectedFilterType(options[3])
          else if (sp.has('cid'))
            setSelectedFilterType(options[0])
          else if (sp.has('sid'))
            setSelectedFilterType(options[1])
          break;
        case 'rfq':
        case 'quote':
        case 'rfd':
        case 'completed':
          if (sp.has('archive'))
            setSelectedFilterType(options[1])
          break;
        default:
          break;
      }
    }
  }, [location])

  function handleShow(id, isManage, customer, listType, role) {
    switch (id) {
      case 1:
        return isManage;
      case 2:
      case 3:
        return isManage && ['Orders', 'Quotes'].includes(listType);
      case 4:
        return isManage && customer != null;
      case 5:
        return isCustomer(role) && !['Quotes'].includes(listType);
      case 6:
        return isManage && ['Orders', 'Quotes', 'Rfqs'].includes(listType);
      case 7:
        return (isSupplier(role) && listType === 'Orders') || (isManage && ['Orders', 'Quotes', 'Rfqs'].includes(listType));
      case 8:
      case 9:
        return isManage && listType === 'Orders';
      default:
        return false;
    }
  }

  function getTableColumns(isManage, customer, role) {
    let selection = false;
    if (customer) {
      selection = true;
    }
    return getColumns(isManage, props.type, selection, role, filterType?.value, isSupplierInvoice, user);
  }

  const getType = () => {
    let title = ''
    switch (props.type) {
      case 'approval':
        title = 'Waiting for Approval'
        return title;
      case 'rfq':
        title = `${upperCase(props.type)}S`
        return title;
      case 'completed':
        title = `RFD ${props.type.charAt(0).toUpperCase() + props.type.slice(1)}`
        return title
      case 'rfd':
        title = 'RFPS'
        return title
      case 'confirmed':
        title = 'Confirmed Orders'
        return title
      case 'order':
        if (isDefaultSupplierTypes(user)) {
          title = 'Received Orders'
          return title
        }
        return 'Orders'
      default:
        title = `${_.capitalize(props.type)}s`
        return title;
    }
  }

  const type = isSupplierInvoice ? 'Supplier Invoices' : getType();
  let headerTitle = type
  if (type === 'RFQS' && isCustomer(user.role)) headerTitle = "Quotes Requested"
  if (type === 'Quotes' && isCustomer(user.role)) headerTitle = "Quotes Waiting For Approval"
  if (type === 'Waiting for Approval' && isCustomer(user.role)) headerTitle = "Order Waiting for Approval"
  if (['completed'].includes(props.type)) headerTitle = "RFP Completed"
  if (['fulfilled'].includes(props.type)) headerTitle = "Shipped Orders"
  if (['in-production'].includes(props.type))
    headerTitle = _.startCase(props.type)
  if (['container'].includes(props.type))
    headerTitle = 'Container Shipments'

  const dialog = {
    required: true,
    component: OrderDialog,
    additional: {
      required: true,
      component: ViewOrderDialog,
      data: {
        isDashboard: false,
      },
    },
  };

  const filterComponent = filterProps => {
    const sendProps = { ...filterProps, filterValue: filterType, setSupplier, setSalesPerson, includeArchive, includeInvoice, setSelectedFilterType, setIncludeArchive, setIncludeInvoice }
    return (
      <div className="flex flex-row">
        {(["order", "rfq", "quote", "rfd", "completed", "invoice"].includes(
          props.type
        ) &&
          hasPermission) ||
        (isCustomer(user.role) && ["order", "invoice"].includes(props.type)) ? (
          <OrderTypeFilter
            {...sendProps}
            options={options}
            role={user.role}
            type={props.type}
            isSupplierInvoice={isSupplierInvoice}
            autoCompleteSxStyle={{
              width: "200px !important",
              ".MuiInput-input": {
                padding: "0 !important",
              },
            }}
          />
        ) : null}
        {props.type === "order" &&
        hasPermission &&
        ["customer", "customer_archive"].includes(filterType?.value) ? (
          <OrderTyepFilterFunction />
        ) : null}
        {!isCustomer(user.role) && (
          <>
            {!["shipment", "container"].includes(props.type) && (
              <CustomerFilter
                {...sendProps}
                isOrderModule
                pageRef={ref}
                autoCompleteSxStyle={{
                  width: "200px !important",
                  ".MuiInput-input": {
                    padding: "0 !important",
                  },
                }}
              />
            )}
          </>
        )}
        {isAdminOrStaff(user) &&
          ["order", "quote", "rfq"].includes(props.type) &&
          !['supplier', 'supplier_archive'].includes(filterType?.value) && (
            <>
              <SalesPersonFilter
                {...sendProps}
                isOrderModule
                pageRef={salesPersonRef}
                autoCompleteSxStyle={{
                  width: "200px !important",
                  ".MuiInput-input": {
                    padding: "0 !important",
                  },
                }}
              />
            </>
          )}
        {props.type === "order" && !isCustomer(user.role) && (
          <>
            <IncludeInvoiceFilter {...sendProps} />
          </>
        )}
        {props.type === "order" && !isCustomer(user.role) && (
          <>
            <IncludeArchiveFilter {...sendProps} />
          </>
        )}
      </div>
    );
  }

  const getMessage = () => {
    const param = { ...hasFilter }
    delete param.stage
    let message = 'Are you sure you want to export all the record(s)'
    if (Object.keys(param).length < 1 || Object.values(param).every(value => value === '')) {
      return message;
    }
    message = `${message} in the current view?`
    return message;
  }

  const headerData = {
    headerTitle,
    search: {
      enabled: true,
    },
    filter: {
      component: filterComponent,
    },
    updateShow: true,
    statusCategory: `${props.type === 'approval' ? 'proof' : props.type}_status`,
    links: !['invoice', 'approval', 'container'].includes(props.type) ? [
      {
        id: 1,
        title: `New ${props.type}`,
        type: 'new',
        value: '',
        link: type === 'RFQS' ? 'request/new?type=request' : `/${props.type}s/new`,
        show: false,
      },
      {
        id: 2,
        title: `Import Multiple ${type}`,
        display: 'list', // Added field to display import options in list or open file document directly
        type: 'import',
        value: props.type,
        link: 'N/A',
        show: false,
        stage: props.type
      },
      {
        id: 6,
        title: `Import Single ${_.capitalize(props.type)}`,
        display: 'list', // Added field to display import options in list or open file document directly
        type: 'import',
        params: { single: true },
        value: props.type,
        link: 'N/A',
        show: false,
        icon: 'download',
      },
      {
        id: 3,
        title: `Import Archive ${type}`,
        display: 'open_file', // Added field to display import options in list or open file document directly
        type: 'import',
        value: props.type,
        params: { archive: 'yes' },
        link: 'N/A',
        icon: 'archive',
        show: false,
      },
      {
        id: 4,
        title: 'Customers',
        type: 'back',
        value: `${props.type}s`,
        link: '/customers',
        show: false,
      },
      {
        id: 5,
        title: `New ${props.type === 'order' ? 'Order' : 'RFQ'}`,
        type: 'new',
        value: '',
        link: type === 'RFQS' ? '/rfqs/request/new?type=request' : '/products',
        show: false,
      },
      {
        id: 7,
        type: 'export',
        title: `Export ${props.type}s`,
        onClick: () => {
          dispatch(
            openDialog({
              children: (
                <Confirm title={`Export ${props.type}s`}
                  content={getMessage()}
                  onSubmit={() => {
                    downloadFile({ params: { export: true, stage: props.type }, endPoint: '/api/orders' })
                      .then(() => dispatch(showMessage({ type: 'success', message: 'File downloaded successfully' })))
                      .catch(() => dispatch(showMessage({ type: 'error', message: 'Failed to download' })))
                  }}
                />
              )
            }))
        }
      },
      {
        id: 8,
        type: 'sample_file',
        title: 'Download single order import excel format',
        path: '/sampleFiles/order/single_order_import_excel_format.xlsx'
      },

      {
        id: 9,
        type: 'sample_file',
        title: ' Download single order with supplier price import excel format',
        path: '/sampleFiles/order/single_order_import_with_supplier_price_excel_format.xlsx'
      },
    ] : [],
  };

  return (
    <>
      <Entities
        headerData={headerData}
        entitiesSelector="orders"
        type={props.type}
        entitiesFor={props.for}
        listType={type}
        statusCategory={headerData.statusCategory}
        getEntities={getOrders}
        getEntityStatuses={getOrderStatuses}
        selectEntities={selectOrders}
        setCustomer={setCustomer}
        setSalesPerson={setSalesPerson}
        setEntitiesSearchText={setOrdersSearchText}
        dialog={dialog}
        handleShow={handleShow}
        getColumns={getTableColumns}
        uniqueName={`${props.type}Table`}
        filterType={filterType}
        includeInvoice={includeInvoice}
        includeArchive={includeArchive}
      />
      <ReallocationDialog />
      <ChooseSupplier />
      <CreateSupplierOrder />
      <ProductDialog />
      <ConfirmInvoiceDialog />
    </>
  );
}
export default Orders;
