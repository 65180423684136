import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AuraBaseDialog from "app/main/apps/common/AuraBaseDialog"
import Button from '@mui/material/Button';
import { Typography } from "@mui/material"
import { showMessage } from 'app/store/fuse/messageSlice';
import { checkNullOrUndefined } from "app/main/apps/common/AuraFunctions";
import { closeViewOrderDialog, confirmSupplierInvoice } from "app/main/dashboards/main/store/widgetsSlice";
import { closeConfirmInvoiceDialog } from "../../store/orderSlice";

function ConfirmInvoiceDialog(props) {
    const [loading, setLoading] = useState()
    const dispatch = useDispatch();
    const confirmInvoiceDialog = useSelector(({ mainDashboardApp, eCommerceApp }) => {
        if (props.isDashboard) {
            return mainDashboardApp?.widgets?.confirmInvoiceDialog;
        }
        return eCommerceApp?.order?.confirmInvoiceDialog;
    });
    const dialogParam = confirmInvoiceDialog?.param;
    /**
    * Close Dialog
    */
    function closeDialog() {
        if (props.isDashboard) {
            dispatch(
                closeViewOrderDialog({
                    field: 'confirmInvoiceDialog',
                })
            );
        } else {
            dispatch(closeConfirmInvoiceDialog());
        }
    }

    const onSubmit = () => {
       // Added condition to avoid backend error undefined
       if(confirmInvoiceDialog && confirmInvoiceDialog?.data && !checkNullOrUndefined(confirmInvoiceDialog?.data?.id)) {
        setLoading(true)
        dispatch(confirmSupplierInvoice({ id: confirmInvoiceDialog?.data?.id })).unwrap().then(() => {
            dialogParam.loadOrdersFun()
            dispatch(
                showMessage({
                    message: "Invoice confirmed successfully",
                    variant: 'info',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                })
            );
            closeDialog()
        }).finally(() => {
            setLoading(false)
        })
       }
    }

    return (
        <AuraBaseDialog
            dialogProps={{ open: confirmInvoiceDialog?.props?.open }}
            closeDialog={closeDialog}
            dialogParam={{ title: 'Confirm Invoice', submitButtonName: 'Yes' }}
            onSubmit={onSubmit}
            additionalButton={<Button onClick={closeDialog} variant='outlined' color='secondary'>No</Button>}
            disableSubmitButton={() => loading}
            noValidate
        >
            <Typography>Are you sure you want to confirm this invoice?</Typography>
        </AuraBaseDialog>
    )
}
export default ConfirmInvoiceDialog