import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';

function AuraBaseDialog(props) {
  const methods = useFormContext();
  const { handleSubmit } = methods || {};
  // Added so we can disable it when backend process is going on. Helps to avoid duplicate records
  const disableCloseIcon = props.disableCloseIcon || false;
  const { dialogParam, closeDialog, paperClass } = props;
  const HeaderComponent = props.header?.component;
  const [closeOnBackdropClick, setCloseOnBackdropClick] = useState(props.closeOnBackdrop || false);

  useEffect(() => {
    if (
      props &&
      (props.closeOnBackdrop === undefined || props.closeOnBackdrop === null) &&
      dialogParam &&
      dialogParam.title
    ) {
      if (dialogParam.title.toLowerCase().startsWith("view")) {
        setCloseOnBackdropClick(true);
      }
    }
    return () => {
      closeDialog();
    }
  }, [])

  function onCloseDialog(event, reason) {
    if(closeOnBackdropClick || (!closeOnBackdropClick && reason !== "backdropClick" && reason !== "escapeKeyDown")) {
      closeDialog();
    }
  }

  return (
    <Dialog
      style={{ zIndex: 99 }}
      classes={{
        paper: `m-24 ${paperClass}`,
      }}
      {...props.dialogProps}
      onClose={onCloseDialog}
      fullWidth
      maxWidth={dialogParam.maxWidth || 'sm'}
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            {dialogParam.title}
          </Typography>
          {closeDialog ? (
            <IconButton
              aria-label="close"
              onClick={onCloseDialog}
              disabled={disableCloseIcon}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Toolbar>
      </AppBar>
      {props.header?.enable && <HeaderComponent {...props.header.data} />}
      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          if (props?.noValidate) {
            props.onSubmit(); // Execute the submission function directly if no validation is required
          } else {
            handleSubmit(props.onSubmit)(); // Ensure handleSubmit is called correctly
          }
        }}
        className="flex flex-col md:overflow-hidden"
      >
        <DialogContent classes={{ root: 'p-24' }}>{props.children}</DialogContent>
        {!dialogParam.hideDialogActions || props.additionalButton || props.showDialogActions ? (
          <DialogActions className="justify-between p-4 pb-16 ">
            <div className="px-16 flex gap-x-10 items-end">
              {(!dialogParam.hideDialogActions) &&
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={props.disableSubmitButton()}
                >
                  {dialogParam.submitButtonName ? dialogParam.submitButtonName : 'Save'}
                </Button>
              }
              {props.showDialogActions && props.showDialogActions}
              {props.additionalButton && props.additionalButton}
            </div>
          </DialogActions>
        ) : null}
      </form>
    </Dialog>
  );
}

export default AuraBaseDialog;
