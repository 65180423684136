import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from '@lodash';
import { getProductSubTypes } from '../../e-commerce/store/productsSlice';

export const getStatusesTypes = createAsyncThunk(
  'settingsApp/configurations/getStatusesTypes',
  async (routeParams, { dispatch, getState }) => {
    routeParams = routeParams || getState().settingsApp.configurations.routeParams;
    const copyRouteParams = { ...routeParams }
    delete routeParams?.mode;
    delete routeParams?.addToProductType;
    const response = await axios.get('/api/statusesTypes', {
      params: routeParams,
    });
    const data = await response.data.data;
    return { data, routeParams, copyRouteParams };
  }
);

export const getWorkflowStatuses = createAsyncThunk(
  'settingsApp/configurations/getWorkflowStatuses',
  async (params) => {
    const response = await axios.get('/api/workflowactions/toStatusesList', {
      params
    });
    const data = await response.data?.map((item) => {
      return { ...item, name: item?.fromStatusesTypeCode?.replace(/_/g, " "), id: item?.fromStatusesTypesId }
    });
    return data;
  }
);

export const getWorkFlowSource = createAsyncThunk(
  'settingsApp/configurations/getWorkFlowSource',
  async () => {
    const response = await axios.get('/api/workflowactions/statusesList');
    const data = await response.data?.map((item) => {
      return { ...item, name: item?.fromStatusesTypeCode?.replace(/_/g, " ") }
    });
    return data;
  }
);

export const getWorkFlowTarget = createAsyncThunk(
  'settingsApp/configurations/getWorkFlowTarget',
  async (params) => {
    const response = await axios.get('/api/workflowactions/statusesListToAdd', {
      params
    });
    const data = await response.data?.map((item) => {
      return { ...item, name: item?.fromStatusesTypeCode?.replace(/_/g, " ") }
    });
    return data;
  }
);

export const getStatusesType = createAsyncThunk(
  'settingsApp/configurations/getStatusesType',
  async ({ id, category }, { dispatch, getState }) => {
    const response = await axios.get(`/api/statusesTypes/${id}`, { params: { category } });
    const data = await response.data.statusType;
    if (data?.custom_layout?.images) {
      data.custom_layout.images = data?.custom_layout?.images.map(item => {
        delete item.data
        return item
      })
    }
    delete data?.custom_layout?.stoneUrl?.data
    return data;
  }
);

export const addStatusesType = createAsyncThunk(
  'settingsApp/configurations/addStatusesType',
  async (inputData, { dispatch, getState }) => {
    const response = await axios.post('/api/statusesTypes', inputData);
    const data = await response.data;
    return data;
  }
);

export const updateStatusesType = createAsyncThunk(
  'settingsApp/configurations/updateStatusesType',
  async (inputData, { dispatch, getState }) => {
    if (inputData?.attachment?.data)
      delete inputData.attachment.data
    const response = await axios.put(`/api/statusesTypes/${inputData.id}`, inputData);
    const data = await response.data?.data;
    if (data?.id) {
      dispatch(showConfigurationAlert({ title: `Product type ${data?.name} updated successfully`, severity: 'success', show: true }))
    } else {
      dispatch(showConfigurationAlert({ title: `An error occurred. Please try again later.`, show: true, severity: 'error' }))
    }

    return data;
  }
);

export const getUniqueStatusTypeCategories = createAsyncThunk(
  'settingsApp/configurations/getUniqueStatusTypeCategories',
  async () => {
    const response = await axios.get('/api/uniqueCategories');
    const data = await response.data?.filter((item) => item.name !== 'workflow_status_change');
    return { data };
  }
);

export const getGroupNames = createAsyncThunk(
  'settingsApp/configurations/getGroupNames',
  async (category) => {
    const response = await axios.get(`/api/groupNames/${category}`);
    const data = await response.data;
    return { category, data };
  }
);

export const getCategoryValues = createAsyncThunk(
  'settingsApp/configurations/getCategoryValues',
  async ({ params }) => {
    const response = await axios.get('/api/categoryValues', { params });
    const data = await response.data;
    return { data, params };
  }
);

const productTypeCalls = (dispatch, category, productTypeId, isCustomProduct) => {
  if (category === 'product_polish_mapping') {
    dispatch(getProductSubTypes({ path: '/api/polishesByProductType', params: { productTypeId, custom_product: isCustomProduct } }));
  } else if (category === 'product_sub_type') {
    dispatch(getProductSubTypes({ path: '/api/productSubTypesByProductType', params: { id: productTypeId, custom_product: isCustomProduct } }));
  }
}

export const createProductType = createAsyncThunk(
  'settingsApp/configurations/createProductType',
  async ({ request = {}, category = '', productTypeId = '', closeComposeDialog = () => { }, listIsCustomProduct }, { dispatch, getState }) => {
    const response = await axios.post(`/api/statusesType`, request);
    const data = await response?.data?.data
    if (data?.id) {
      dispatch(showConfigurationAlert({ title: `Product type ${data?.name} ${_.capitalize(response?.data?.status || 'Created')} successfully.`, show: true, severity: 'success' }))
      if (category === 'product_type') {
        dispatch(getStatusesTypes({ category, mode: false }));
      } else if (productTypeId) {
        productTypeCalls(dispatch, category, productTypeId, listIsCustomProduct);
      }
      closeComposeDialog()
    } else {
      dispatch(showConfigurationAlert({ title: `An error occurred. Please try again later.`, severity: 'error', show: true }))
    }

    return data;
  }
);

export const createWorkflowStatus = createAsyncThunk(
  'settingsApp/configurations/createWorkflowStatus',
  async ({ request = {}, selectedCode, closeComposeDialog = () => { } }, { dispatch, getState }) => {
    const response = await axios.post(`/api/workflowactions/workflowStatus`, request);
    const data = await response?.data
    // if (data?.id) {
    dispatch(showConfigurationAlert({ title: `Workflow status created successfully.`, show: true, severity: 'success' }))
    dispatch(getWorkflowStatuses({ code: selectedCode }));
    closeComposeDialog()
    // } else {
    //   dispatch(showConfigurationAlert({ title: `An error occurred. Please try again later.`, severity: 'error', show: true }))
    // }
    return data;
  }
);

export const deleteProductType = createAsyncThunk(
  'settingsApp/configurations/deleteProductType',
  async ({ params = {}, id = '', productId = '', name = '', productDelete = () => { }, listIsCustomProduct }, { dispatch, getState }) => {
    const response = await axios.delete(`/api/statusesType/${id}`, { params: { ...params } }).catch(error => error)
    const data = await response?.data;
    if (response?.name === "AxiosError" && (response?.code === "ERR_BAD_RESPONSE" || response?.message)) {
      dispatch(showConfigurationAlert({ title: response?.message, show: true, severity: 'error' }));
    } else {
      const category = params?.category;
      if (category === 'product_type') {
        if (response?.data?.status === "METHOD_NOT_ALLOWED") {
          dispatch(showConfigurationAlert({ title: response?.data?.message, show: true, severity: 'error' }));
          productDelete(false)
          return data
        }
        dispatch(getStatusesTypes({ category, mode: false }));
      } else if (productId) {
        productTypeCalls(dispatch, category, productId, listIsCustomProduct);
      }
      dispatch(showConfigurationAlert({ title: `${name} deleted successfully.`, severity: 'success', show: true }))
    }
    productDelete(false)
    return data;
  }
);

export const deleteWorkflow = createAsyncThunk(
  'settingsApp/configurations/deleteProductType',
  async ({ payload = {}, selectedCode, productDelete = () => { } }, { dispatch, getState }) => {
    const response = await axios.delete(`/api/workflowactions/deleteWorkflowStatus`, { data: payload }).catch(error => error)
    const data = await response?.data;
    if (response?.name === "AxiosError" && (response?.code === "ERR_BAD_RESPONSE" || response?.message)) {
      dispatch(showConfigurationAlert({ title: response?.message, show: true, severity: 'error' }));
    } else {
      dispatch(getWorkflowStatuses({ code: selectedCode }));
      dispatch(showConfigurationAlert({ title: `Deleted successfully.`, severity: 'success', show: true }))
    }
    productDelete(false)
    return data;
  }
);

export const addToProductType = createAsyncThunk(
  'settingsApp/configurations/addToProductType',
  async ({ requestBody, productTypeId = '', closeComposeDialog = () => { }, listIsCustomProduct }, { dispatch, getState }) => {
    const response = await axios.post(`/api/productTypeMapping`, requestBody).catch(error => error)
    const data = await response?.data;
    if (response?.name === "AxiosError" && (response?.code === "ERR_BAD_RESPONSE" || response?.message)) {
      dispatch(showConfigurationAlert({ title: response?.message, show: true, severity: 'error' }));
    } else if (response?.data?.status === "CREATED") {
      if (productTypeId?.toString() === requestBody?.product_type_id) {
        productTypeCalls(dispatch, requestBody?.category, productTypeId, listIsCustomProduct);
      }
      dispatch(showConfigurationAlert({ title: `${requestBody?.category === 'product_sub_type' ? 'Product sub type' : 'Polish'} successfully ${response?.data?.status === 'CREATED' ? 'mapped' : 'updated'}.`, severity: 'success', show: true }))
      closeComposeDialog();
    } else {
      dispatch(showConfigurationAlert({ title: 'An error occurred. Please try again later.', severity: 'error', show: true }))
    }
    return data;
  })

const configurationsAdapter = createEntityAdapter({});

export const { selectAll: selectConfigurations, selectById: selectConfigurationsById } =
  configurationsAdapter.getSelectors((state) => state.settingsApp.configurations);

const configurationsSlice = createSlice({
  name: 'settingsApp/configurations',
  initialState: configurationsAdapter.getInitialState({
    searchText: '',
    category: null,
    productType: null,
    isCustomProduct: true,
    workflowStatus: null,
    routeParams: {},
    categoryNames: [],
    productTypes: [],
    dialogProductTypes: [],
    groupNames: {},
    configurationDialogProductSubTypes: [],
    configurationDialogProductPolishMapping: [],
    workflowSources: [],
    workflowTargets: [],
    workflowStatuses: [],
    configurationDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
    showAlert: {
      show: false,
      title: '',
      severity: 'success'
    }
  }),
  reducers: {
    setConfigurationsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    openNewConfigurationDialog: (state, action) => {
      state.configurationDialog = {
        type: 'new',
        props: {
          open: true,
        },
        data: null,
        createProduct: action?.payload?.createProduct,
        addToProductType: action?.payload?.addToProductType,
        radioFilter: action?.payload?.radioFilter,
        isCustomProduct: action?.payload?.isCustomProduct
      };
    },
    closeNewConfigurationDialog: (state, action) => {
      state.configurationDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
        radioFilter: action?.payload?.radioFilter,
        isCustomProduct: action?.payload?.isCustomProduct
      };
    },
    openEditConfigurationDialog: (state, action) => {
      const { data = {}, productTypeId = null } = action.payload || {};
      state.configurationDialog = {
        type: 'edit',
        props: {
          open: true,
        },
        data,
        productTypeId,
        isCustomProduct: action?.payload?.isCustomProduct
      };
    },
    closeEditConfigurationDialog: (state, action) => {
      state.configurationDialog = {
        type: 'edit',
        props: {
          open: false,
        },
        data: null,
      };
    },
    setCategory: (state, action) => {
      state.category = action.payload || null;
    },
    setProductType: (state, action) => {
      state.productType = action.payload || null;
    },
    setIsCustomProduct: (state, action) => {
      state.isCustomProduct = action.payload
    },
    setWorkflowStatus: (state, action) => {
      state.workflowStatus = action.payload || null;
    },
    setProductTypes: (state, action) => {
      configurationsAdapter.setAll(state, action.payload || []);
    },
    showConfigurationAlert: (state, action) => {
      state.showAlert = {
        ...state.showAlert,
        ...action.payload
      }
    }
  },
  extraReducers: {
    [getStatusesTypes.fulfilled]: (state, action) => {
      const { data, routeParams, copyRouteParams } = action.payload;
      if (!copyRouteParams?.mode && !copyRouteParams?.addToProductType) {
        configurationsAdapter.setAll(state, data);
        state.routeParams = routeParams;
        state.searchText = '';
      } else {
        console.log('routeParams', routeParams)
        const filterItems = _.uniqBy(data, 'name');

        if (copyRouteParams?.category === "product_sub_type") {
          state.configurationDialogProductSubTypes = filterItems;
        } else if (copyRouteParams?.category === "product_polish_mapping") {
          state.configurationDialogProductPolishMapping = filterItems;
        } else if (!routeParams?.dialog) {
          configurationsAdapter.setAll(state, []);
          state.productTypes = filterItems;
        } else {
          state.productTypes = filterItems;
        }
      }
    },
    [getWorkflowStatuses.fulfilled]: (state, action) => {
      state.workflowStatuses = action.payload
    },
    [getWorkFlowSource.fulfilled]: (state, action) => {
      state.workflowSources = action.payload
    },
    [getWorkFlowTarget.fulfilled]: (state, action) => {
      state.workflowTargets = action.payload
    },
    [getStatusesType.fulfilled]: (state, action) => {
      const { data } = action.payload;
    },
    [updateStatusesType.fulfilled]: configurationsAdapter.upsertOne,
    [addStatusesType.fulfilled]: configurationsAdapter.addOne,
    [getUniqueStatusTypeCategories.fulfilled]: (state, action) => {
      state.categoryNames = action.payload.data;
    },
    [getGroupNames.fulfilled]: (state, action) => {
      state.groupNames[action.payload.category] = action.payload.data;
    },
  },
});

export const {
  setConfigurationsSearchText,
  openNewConfigurationDialog,
  closeNewConfigurationDialog,
  openEditConfigurationDialog,
  closeEditConfigurationDialog,
  setCategory,
  setProductTypes,
  setProductType,
  setIsCustomProduct,
  setWorkflowStatus,
  showConfigurationAlert
} = configurationsSlice.actions;

export default configurationsSlice.reducer;
