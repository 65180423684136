import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from '@lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import BasicInfoTab from 'app/main/apps/e-commerce/product/tabs/BasicInfoTab';
import { getCategoryValues } from 'app/main/apps/settings/store/configurationsSlice';
import FuseUtils from '@fuse/utils';
import { authPermissions } from 'app/auth';
import AuraControlledAutocomplete from 'app/main/apps/common/AuraControlledAutocomplete';
import CustomProductFormContent from 'app/main/apps/e-commerce/product/dialogs/CustomProductFormContent';
import { getEcomWidgetsOrderItems } from 'app/main/dashboards/main/store/widgetsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { matchSorter } from 'match-sorter';
import { useParams } from 'react-router-dom';
import { calculateWeight, checkNonEmptyOrNull } from 'app/main/apps/common/AuraFunctions';
import AuraBaseDialog from '../../../common/AuraBaseDialog';
import {
  getProductColors,
  getProducts,
  getProductSubTypes,
  getProductTypes,
  getStatusesTypes,
  setSelectedProducts,
  getProductPolishes,
  updateProductSubTypeAndPolishes,
  updateProductPolishes
} from '../../store/productsSlice';
import { closeProductDialog, saveProduct } from '../../store/productSlice';
import getInitValues, { getSchema } from '../ProductBase';
import OrderItems from '../../order/tabs/OrderItems';
import { getOrder, placeReOrder } from '../../store/orderSlice';
import { getSuppliers, quoteToOrder } from '../../store/rfqsSlice';

function ProductDialog(props) {
  const dispatch = useDispatch();
  const productDialog = useSelector(({ eCommerceApp }) => eCommerceApp.product.productDialog);
  const suppliersList = useSelector((state) => state.eCommerceApp.rfqs.suppliersList);
  const selectedProducts = useSelector(({ eCommerceApp }) => eCommerceApp.products.selectedProducts);
  const unmodifiedProducts = useSelector(({ eCommerceApp }) => eCommerceApp.products.unmodifiedProducts);
  const tempSchema = getSchema();
  const [schema, setSchema] = useState(tempSchema);
  const [categoryTypes, setCategoryTypes] = useState([]);
  const currentUser = useSelector(({ user }) => user);
  const isManage = currentUser && FuseUtils.hasPermission(authPermissions.manage, currentUser.role);
  const dialogParam = productDialog.param;
  const Param = useParams()
  const { orderId } = Param

  const hideQty = ['Create Vas Order With Proof', 'Create Vas Order'].includes(dialogParam?.action)

  const methods = useForm({
    mode: 'onChange',
    defaultValues: getInitValues(dialogParam?.name),
    resolver: yupResolver(schema),
  });

  const { reset, formState, control, getValues, watch, setValue } = methods;
  const productType = watch('type');
  const productSubType = watch('sub_type');

  const { dirtyFields, isValid, errors } = formState;
  const { fields } = useFieldArray({
    name: 'products',
    control,
    keyName: 'field_id'
  });

  const [dialogTitle, setDialogTitle] = useState('Product');
  const [dialogActionLabel, setDialogActionLabel] = useState('Create Order');

  const getCustomProductButtonLabel = () => {
    const dialogStage = dialogParam?.stage
    if (dialogStage === 'quote') return 'Add To Quote'
    if (dialogStage === 'rfq') return 'Add To RFQ'
    return 'Add To Order'
  }

  const getCustomProductEditButtonLabel = (cloneProduct) => {
    if (cloneProduct) return 'Clone'
    return 'Update'
  }

  /**
   * Initialize Dialog with Data
   */
  const initDialog = useCallback(() => {
    const currentSchema = getSchema(dialogParam.name, hideQty, dialogParam?.stage, dialogParam.orderType);
    setSchema(currentSchema);
    if (productDialog.data) {
      const data = getInitValues(dialogParam.name, productDialog.data);
      reset({ ...data });
    }
    if (dialogParam) {
      const title = dialogParam.title || `Update ${dialogParam.name}`;
      setDialogTitle(title);
    }
    if (['supplierOrderDirectShipment'].includes(dialogParam.name)) {
      setDialogActionLabel(dialogParam.title)
    }
    if (dialogParam && (dialogParam.name === 'product' || dialogParam.name === 'custom_product' || dialogParam.name === 'edit_custom_product')) {
      if (dialogParam.name === 'custom_product') {
        const addButtonLabel = getCustomProductButtonLabel()
        setDialogActionLabel(addButtonLabel);
      }
      if (dialogParam.name === 'edit_custom_product') {
        const editButtonLabel = getCustomProductEditButtonLabel(dialogParam?.cloneProduct)
        setDialogActionLabel(editButtonLabel);
      }
      dispatch(getProductColors({}));
      // dispatch(getProductTypes({ params: { custom_product: true } }));
      dispatch(getStatusesTypes({
        params: {
          category: 'product_type',
          custom_product: true
        }
      }))
      dispatch(
        getCategoryValues({
          params: { c: 'product_category' },
        })
      )
        .unwrap()
        .then((response) => {
          const statusesTemp = response && response.data ? response.data : [];
          setCategoryTypes(statusesTemp);
        });
    }
  }, [dialogParam, dispatch, productDialog.data, reset]);

  /**
   * On Dialog Open
   */
  useEffect(() => {
    if (productDialog.props.open) {
      initDialog();
    }
  }, [productDialog.props.open, initDialog]);

  const getType = () => {
    if (dialogParam.action === 'Create Vas Order') return 'vas'
    if (['Create Stone Order', 'Create Supplier Order', 'Create Direct Shipment Supplier Order'].includes(dialogParam.action)) return 'stone'
    return 'art/design,vas'
  }


  useEffect(() => {
    if (productDialog.props.open) {
      reset()

      if (isDialogType('supplierOrder')) {
        dispatch(getSuppliers({ suppliers: [], params: { type: 'stone' } }));
      }

      if (isDialogType('supplierOrderDirectShipment')) {
        dispatch(getSuppliers({ suppliers: [], params: { type: getType() } }));
        dispatch(getEcomWidgetsOrderItems({ oid: dialogParam.orderNo, stage: dialogParam.stage }))
          .then((data) => {
            if (data && data.payload) {
              const products = data.payload?.map(d => ({
                ...d,
                stone_color: { name: d.color },
                type: { name: d.type },
                unit_cost: d.unit_cost,
                vas: { ...d.vas, textPrice: null, imagePrice: null }
              }))
              reset({ products })
            }
          });
      }
    }
  }, [productDialog.props.open]);

  /*
   * Call Product subtype and polish end point based on product type id
   * 
   */

  useEffect(() => {
    if (productType?.name) {
      let additionalParam = {custom_product: false};
      if (isDialogType('custom_product') || isDialogType('edit_custom_product')) {
        additionalParam = {custom_product: true};
      }
      dispatch(getProductSubTypes({ path: '/api/productSubTypesByProductType', params: { id: productType?.id, ...additionalParam  } }));
      dispatch(getProductPolishes({ path: '/api/polishesByProductType', params: { productTypeId: productType?.id, ...additionalParam  } }));
    } else {
      dispatch(updateProductSubTypeAndPolishes({ data: [] }))
      setValue('sub_type', '')
      setValue('polish', '')
    }
  }, [productType?.name])

  /**
   * Close Dialog
   */
  function closeDialog(type) {
    if (isDialogType('supplierOrder'))
      dispatch(setSelectedProducts({ data: unmodifiedProducts }))
    dispatch(closeProductDialog());
  }

  function isDialogType(name) {
    return dialogParam && dialogParam.name === name;
  }
  /**
   * Form Submit
   */
  function onSubmit(data) {
    if (isDialogType('product')) {
      dispatch(saveProduct(data)).then(() => {
        if (dialogParam.loadProducts === 'yes') {
          dispatch(getProducts());
        }
      })
    }
    if (isDialogType('custom_product') || isDialogType('edit_custom_product')) {
      data.isCustomProduct = 'yes';
      data.addToOrderList = 'yes';
      data.is_custom = dialogParam?.isCustomProduct
      data.cloneProduct = dialogParam?.cloneProduct
      const param = typeof data.polish === 'string' ? data?.polish : data?.polish?.inputValue || data?.polish?.name
      if (checkNonEmptyOrNull(data.weight)) {
        const { length, width, height } = data
        data.weight = calculateWeight(isManage, length, width, height)
      }
      dispatch(saveProduct({ ...data, polish: param })).then((res) => {

        if (dialogParam.loadProducts === 'yes') {
          dispatch(getProducts());
        }
        // if (orderId) {
        //   dispatch(getOrder({ orderId }))

        // }
        if (isDialogType('edit_custom_product' && !dialogParam?.cloneProduct)) {
          dispatch(showMessage({ message: 'Success', type: 'success' }));
        } else if (isDialogType('custom_product') || dialogParam?.cloneProduct) {
          dispatch(showMessage({ message: 'Custom product added successfully', type: 'success' }))
        }
      });
    }
    if (isDialogType('supplierOrder')) {
      const payload = {
        supplier: { id: data?.selected_supplier?.id },
        order_details: data.products.map(product => ({ product: { id: product.id }, quantity: product.quantity, unit_cost: product.unit_cost }))
      }
      dispatch(placeReOrder(payload))
        .then((res) => {
          if (!res.error) {
            dispatch(showMessage({ message: 'Success', type: 'success' }));
            dialogParam.loadProducts()
          }
        });
    }
    if (isDialogType('supplierOrderDirectShipment')) {
      let payload = {}
      if (['Create Vas Order With Proof', 'Create Vas Order'].includes(dialogParam.action)) {
        payload = {
          action_name: dialogParam.action,
          supplier: { id: data?.selected_supplier?.id },
          order_details: data.products.map(p => ({ id: p.id, vas: JSON.stringify(p.vas) }))
        }
      }
      else {
        payload = {
          action_name: dialogParam.action,
          supplier: { id: data?.selected_supplier?.id },
          order_details: data.products.map(p => ({ id: p.id, product: { id: p.product_id, unit_cost: p.unit_cost } }))
        }
      }
      dispatch(quoteToOrder({ payload, id: dialogParam.orderNo }))
        .then((res) => {
          if (!res.error) {
            dispatch(showMessage({ message: 'Success', type: 'success' }));
            dialogParam.loadProducts()
          }
        });
    }
    closeDialog();
  }

  function disableSubmitButton() {
    if (isDialogType('supplierOrder'))
      return !isValid
    return !!(_.isEmpty(dirtyFields) || !isValid);
  }

  return productDialog && productDialog.props && productDialog.props.open ? (
    <FormProvider {...methods}>
      <AuraBaseDialog
        dialogProps={productDialog.props}
        closeDialog={closeDialog}
        dialogParam={{
          title: dialogTitle, maxWidth: 'md',
          submitButtonName: dialogActionLabel,
          hideDialogActions: dialogParam?.hideDialogActions ? dialogParam?.hideDialogActions : false
        }}
        onSubmit={onSubmit}
        disableSubmitButton={disableSubmitButton}
        maxWidth="md"
      >
        {isDialogType('product') && (
          <BasicInfoTab isManage={isManage} categoryTypes={categoryTypes} parent="order" />
        )}
        {(isDialogType('custom_product') || isDialogType('edit_custom_product')) && (
          <CustomProductFormContent
            isManage={isManage}
            categoryTypes={categoryTypes}
            parent="order"
            mode={dialogParam?.name}
            isReadOnly={dialogParam?.isReadOnly}
            customerId={dialogParam?.customerId}
            stage={dialogParam?.stage}
            orderType={dialogParam?.orderType}
            hideAdditionalFields={dialogParam?.hideAdditionalFields}
            isCustomProduct={dialogParam?.isCustomProduct}
            cloneProduct={dialogParam?.cloneProduct}
          />
        )}
        {(isDialogType('supplierOrder') || isDialogType('supplierOrderDirectShipment')) && (
          <>
            <div className='w-1/4 mb-14'>
              <AuraControlledAutocomplete
                inputValues={suppliersList}
                control={control}
                controlName='selected_supplier'
                labelName="Select Supplier *"
                fullWidth={false}
                hideClearable
                filterOptions={(options, { inputValue }) =>
                  matchSorter(options, inputValue, {
                    keys: ['name', 'email'],
                    threshold: matchSorter.rankings.STARTS_WITH,
                  })
                }
                renderOption={(props1, option) => {
                  return <div
                    {...props1}
                    className='MuiAutocomplete-option !block py-2 px-10'
                  >
                    <div className="font-medium">{option.name}</div>
                    <div className="font-light text-10 text-gray-600">{option.email}</div>
                  </div>
                }}
              />
            </div>

            <OrderItems fields={fields} type={dialogParam.name} hideQty={hideQty} directShipment={isDialogType('supplierOrderDirectShipment')} priceEditable />
          </>
        )}
      </AuraBaseDialog>
    </FormProvider>
  ) : (
    <></>
  );
}

export default ProductDialog;