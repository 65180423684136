import { lazy } from 'react';
import { authRoles } from 'app/auth';

import Orders from 'app/main/apps/e-commerce/orders/Orders';

const Product = lazy(() => import('./product/Product'));
const Order = lazy(() => import('./order/Order'));
const OrderImports = lazy(() => import('./orders/orderImports/OrderImports'))
const SingleOrderImports = lazy(() => import('./orders/orderImports/SingleOrderImports'))
const RequestQuote = lazy(() => import('./order/RequestQuote'));

const ECommerceAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.staff,
  routes: [
    {
      path: 'orders',
      element: <Orders type="order" for="orders" />,
    },
    {
      path: 'orders/imports',
      element: <OrderImports />,
    },
    {
      path: 'orders/single-imports',
      element: <SingleOrderImports type='orders' />
    },
    {
      path: 'products/price-book-imports',
      element: <SingleOrderImports type='price_book' />
    },
    {
      path: 'products/:productId/*',
      element: <Product />,
    },
    {
      path: 'orders/:orderId',
      element: <Order type="order" />,
    },
    {
      path: 'rfq/:orderId',
      element: <Order type="rfq" />,
    },
    {
      path: 'quotes/:orderId',
      element: <Order type="quote" />,
    },
    {
      path: 'rfqs/:orderId',
      element: <Order type="rfq" />,
    },
  ],
};

export default ECommerceAppConfig;
