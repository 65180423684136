import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { clone, cloneDeep, filter, isEmpty } from 'lodash';
import moment from 'moment';
import { resetBoxNumbersForUncheckedOrderItems } from '../shipments/utility';
import { getSelectedRecords, isValSelected } from '../../common/AuraFunctions';


export const getAvailableOrders = createAsyncThunk(
  'backofficeApp/shipmentOrder/getAvailableOrders',
  async ({ params = {}, location = false }, { dispatch, getState }) => {
    dispatch(setLoading());
    const response = typeof (params) === 'string' ? await axios.get(`/api/availableOrdersForShipment?${params}`)
      : await axios.get(`/api/availableOrdersForShipment`, { params });
    let data = await response.data.availableOrders;
    data = data?.map((item) => {
      item.id = item?.shipTo
      return item
    })
    dispatch(resetLoading());
    return data || [];
  }
);

export const createShipment = createAsyncThunk(
  'backofficeApp/shipmentOrder/createShipment',
  async (inputData, { dispatch, getState }) => {
    const { payload } = getState().backofficeApp.shipmentOrder.shipmentDialog;
    const formatedDate = moment(inputData.schedule).format('YYYY-MM-DD')
    const enquiries = payload.map(p => ({ ...p, schedule: formatedDate, notes: inputData.note }))
    const response = await axios.post('/api/createShipment', {
      enquiries,
    });
    const data = await response.data;
    dispatch(getAvailableOrders({}))
    return data;
  }
);

export const viewShipment = createAsyncThunk(
  'backofficeApp/shipmentOrder/viewShipment',
  async (inputData, { dispatch }) => {
    dispatch(setLoading());
    // Added condition to avoid backend error
    if (inputData === null || inputData === undefined) {
      dispatch(resetLoading());
      const shipmentArray = new Array(1);
      return {shipment: shipmentArray};
    }
    const response = await axios.get(`api/shipment/${inputData}`)
      .finally(() => dispatch(resetLoading()))
      return response.data;
  }
);

export const editShipment = createAsyncThunk(
  'backofficeApp/shipmentOrder/viewShipment',
  async (inputData, { dispatch }) => {
    const response = await axios.put(`api/shipment/${inputData?.shipment_id}?e=true`, {
      container_number: inputData?.container_number
    })
    return response.data;
  }
);

export const getOrderTypeLocations = createAsyncThunk(
  'backofficeApp/shipmentOrder/getOrderTypeLocations',
  async (params = {}, { dispatch }) => {
    const response = await axios.get(`api/getCustomerLocation`, { params });
    return response.data || [];
  }
);

export const initState = {
  loading: false,
  searchText: '',
  customer: null,
  entities: [],
  selectedItems: [],
  shipmentDialog: {
    props: {
      open: false,
    },
    data: null,
  },
  containerDialog: {
    props: {
      open: false,
    },
    data: null,
  },
  viewShipmentData: {
    shipment: []
  },
  orderTypeLocations: [],
  supplierOrderType: null,
  orderType: null,
  orderTypeLocation: null
};

const shipmentOrdersAdapter = createEntityAdapter({});

export const { selectAll: selectOrders, selectById: selectOrderById } =
  shipmentOrdersAdapter.getSelectors((state) => state.backofficeApp.shipmentOrder);

const shipmentOrderSlice = createSlice({
  name: "backofficeApp/shipmentOrder",
  initialState: shipmentOrdersAdapter.getInitialState(initState),
  reducers: {
    setLoading: (state, action) => {
      state.loading = true;
    },
    resetLoading: (state, action) => {
      state.loading = false;
    },
    setOrdersSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    setCustomer: (state, action) => {
      state.customer = action.payload || null;
    },
    setSupplierOrderType: (state, action) => {
      state.supplierOrderType = action.payload || null;
    },
    openShipmentDialog: (state, action) => {
      state.shipmentDialog = {
        props: {
          open: true,
        },
        data: action.payload.data,
        param: action.payload.param,
        payload: action.payload.payload,
      };
    },
    closeShipmentDialog: (state, action) => {
      state.shipmentDialog = {
        props: {
          open: false,
        },
        data: null,
        param: null,
      };
    },
    openContainerDialog: (state, action) => {
      state.containerDialog = {
        props: {
          open: true,
        },
        data: action.payload.data,
        param: action.payload.param,
        payload: action.payload.payload,
      };
    },
    closeContainerDialog: (state, action) => {
      state.containerDialog = {
        props: {
          open: false,
        },
        data: null,
        param: null,
      };
    },
    updateOrderItems: (state, action) => {
      const {
        field,
        value,
        customerId,
        orderId,
        orderDetailId,
        updateCustomer,
        skipOrder,
        isSelect,
        orderItemCheckBox,
      } = action.payload;
      let changes = {};
      if (updateCustomer) {
        changes = { [field]: value };
      }
      changes = {
        ...changes,
        orders: state.entities[customerId]?.orders.map((item) => {
          let orderLevel = true;
          if (orderId != null && item.id !== orderId) {
            orderLevel = false;
          }
          if (orderLevel) {
            let updateItem = item;
            updateItem.order_detail = updateItem.order_detail.map((detail) => {
              let changeRequired = true;
              if (orderDetailId != null && detail.id !== orderDetailId) {
                changeRequired = false;
              }
              if (changeRequired) {
                if (isSelect) {
                  return {
                    ...detail,
                    [field]: value,
                    selected_quantity: detail.quantity,
                  };
                }
                return { ...detail, [field]: value, is_selected: 1 };
              }
              return detail;
            });
            if (!skipOrder) {
              const hasAnyOneOrderSelected = updateItem.order_detail.some(
                (obj) => obj.is_selected
              );
              updateItem = { ...item, [field]: hasAnyOneOrderSelected ? 1 : 0 };
            }
            return updateItem;
          }
          return item;
        }),
        split_order: state.entities[customerId]?.split_order.map((item) => {
          let selected = value;
          let requiredUpdate = true;
          if (isSelect) {
            selected = item.quantity + item.selected_quantity;
            if (
              orderDetailId &&
              (item.order_id !== orderId || item.id !== orderDetailId) &&
              !updateCustomer
            ) {
              requiredUpdate = false;
            }
            if (item.order_id !== orderId && !updateCustomer) {
              requiredUpdate = false;
            }
          } else if (item.order_id !== orderId || item.id !== orderDetailId) {
            requiredUpdate = false;
          }
          if (requiredUpdate) {
            return {
              ...item,
              selected_quantity: selected,
              quantity: item.requested_quantity - selected,
              amount: (
                (item.requested_quantity - selected) *
                item.unit_price
              ).toFixed(2),
            };
          }
          return item;
        }),
      };
      shipmentOrdersAdapter.updateOne(state, { id: customerId, changes });

      if (
        orderItemCheckBox === "suplier-order-item-check-box" &&
        isSelect &&
        field === "is_selected" &&
        value === 0
      ) {
        const selectedOrderInitialBoxNumber =
          resetBoxNumbersForUncheckedOrderItems(changes?.orders);
        state.entities[customerId || null].orders =
          selectedOrderInitialBoxNumber;
      }
    },
    updateContainerOrderItems: (state, action) => {
      const {
        field,
        value,
        customerId,
        orderId
      } = action.payload;
      let start = parseInt(1, 10);
      const updatedEntities = cloneDeep(state.entities);
      const selectedOrders = [];
      updatedEntities[customerId]?.orders.map((item) => {
        if (item.id === orderId || isValSelected(item.is_selected)) {
          const selectedOrder = clone(item);
          const selectedOrderDetails = [];
          const updateItem = item;
          if (item.id === orderId) {
            updateItem[field] = value;
          }
          updateItem.order_detail = updateItem.order_detail.map((detail) => {
            const updateObject = {};
            if (item.id === orderId) {
              // Selected orders.
              // update is selected field
              // Remove box number if is_selected = 0
              // add box number if is_selected = 1
              updateObject[field] = value;
              if (field === "is_selected" && isValSelected(value)) {
                updateObject.box_number_from = start;
                const quantity = detail?.box_quantity || detail?.quantity;
                const boxTo = start - 1 + parseInt(quantity, 10);
                updateObject.box_quantity = quantity;
                updateObject.box_number_to = boxTo;
                start = boxTo + 1;
                const selectedOrderDetail = {
                  ...updateObject,
                  actual_Quantity: detail.quantity,
                };
                selectedOrderDetails.push({
                  ...detail,
                  ...selectedOrderDetail,
                });
                return { ...detail, ...updateObject };
              }
              updateObject.box_number_from = null;
              updateObject.box_quantity = null;
              updateObject.box_number_to = null;
              return { ...detail, ...updateObject };
            }
            if (isValSelected(detail.is_selected)) {
              // Other orders. Update box number only
              updateObject.box_number_from = start;
              const quantity = detail?.box_quantity || detail?.quantity;
              const boxTo = start - 1 + parseInt(quantity, 10);
              updateObject.box_quantity = quantity;
              updateObject.box_number_to = boxTo;
              start = boxTo + 1;
              const selectedOrderDetail = {
                ...updateObject,
                actual_Quantity: detail.quantity,
              };
              selectedOrderDetails.push({ ...detail, ...selectedOrderDetail });
              return { ...detail, ...updateObject };
            }
            return detail;
          });
          if (isValSelected(item.is_selected)) {
            selectedOrder.order_detail = selectedOrderDetails;
            selectedOrders.push(selectedOrder);
          }
          return updateItem;
        }
        return item;
      });
      // Uncheck all other location orders if present
      const otherLocations = Object.keys(updatedEntities).filter((val) => !val.includes(customerId));
      otherLocations.forEach((customerLocation) => {
        updatedEntities[customerLocation]?.orders.map((item) => {
          const updateItem = item;
          if(isValSelected(updateItem.is_selected)) {
            updateItem.is_selected = 0;
          }
          updateItem.order_detail = updateItem.order_detail.map((detail) => {
            const updateObject = detail;
            if(isValSelected(detail.is_selected)) {
              updateObject.is_selected = 0;
              updateObject.box_number_from = null;
              updateObject.box_quantity = null;
              updateObject.box_number_to = null;
            }
            return { ...detail, ...updateObject };
          })
          return updateItem;
        });
      });
      state.entities = updatedEntities;
      state.selectedItems = selectedOrders;
    },
    updateContainerOrderItem: (state, action) => {
      const { customerId, orderId, orderItem } = action.payload;
      // Assign order to the entity
      // Set last boxnumber to start
      // Use start from next order
      // Update from next order
      let start = parseInt(1, 10);
      const updatedEntities = cloneDeep(state.entities);
      const selectedOrders = [];
      let updateOrder = false;
      updatedEntities[customerId]?.orders.map((item, itemIndex) => {
        const isItemSelected = isValSelected(item.is_selected);
        const selectedOrder = clone(item);
        if (item.id === orderId || updateOrder) {
          // Current order which is updated and next orders comes here
          // Change from current order
          updateOrder = true;
          if (item.id === orderId || isValSelected(item.is_selected)) {
            if (item.id === orderId) {
              const updateItem = item;
              // Selected Order. Attach the whole order return item
              const filteredRecords =  getSelectedRecords(orderItem?.order_detail);
              if (filteredRecords && filteredRecords.length > 0) {
                start = filteredRecords[filteredRecords.length - 1].box_number_to + 1;
                // Add to selectedOrders only if there as any order_detail is slected
                selectedOrder.order_detail = filteredRecords; // TODO: No actual_Quantity added here
                selectedOrders.push(selectedOrder);
              }
              if (!filteredRecords || isEmpty(filteredRecords)|| ( filteredRecords && filteredRecords.length <= 0)) {
                // No order details is slected so make the order is_selected to false
                updateItem.is_selected = false;
              }
              // Attach the whole order return item from the edit dialog
              updateItem.order_detail = orderItem.order_detail;
              return updateItem;
            }
            const updateItem = item;
             if(isValSelected(item.is_selected)){
              const selectedOrderDetails = [];
              updateItem.order_detail = updateItem.order_detail.map((detail) => {
                if (isValSelected(detail.is_selected)) {
                  const updateObject = {};
                  updateObject.box_number_from = start;
                  const quantity = detail?.box_quantity || detail?.quantity;
                  const boxTo = start - 1 + parseInt(quantity, 10);
                  updateObject.box_quantity = quantity;
                  updateObject.box_number_to = boxTo;
                  start = boxTo + 1;
                  const selectedOrderDetail =  {...updateObject, "actual_Quantity": detail.quantity};
                  selectedOrderDetails.push({ ...detail, ...selectedOrderDetail});
                  return { ...detail, ...updateObject };
                }
                return detail;
              });
              selectedOrder.order_detail = selectedOrderDetails;
              selectedOrders.push(selectedOrder);
            }
            return updateItem;
          }
          return item;
        }
        if (isValSelected(item.is_selected)  || !updateOrder) {
          // Previous orders Comes here
          // Store the last box number to to start
          const filteredRecords =  getSelectedRecords(item?.order_detail);
          if (filteredRecords && filteredRecords.length > 0) {
            start =
              filteredRecords[filteredRecords.length - 1].box_number_to + 1;
          }
          // Store only selected order details
          if(isItemSelected) {
            selectedOrder.order_detail = filteredRecords;
            selectedOrders.push(selectedOrder);
          }
        }
        // returned same item so previous Orders will not be changed
        return item;
      });
      state.entities = updatedEntities;
      state.selectedItems = selectedOrders;
    },
    updateBoxNumber: (state, action) => {
      // TODO shipments are based on customer
      // Supplier dont't have customer so null what to do on this case
      // But it's working with null value. Need to analyze and change
      const { customerId, orders } = action.payload;
      state.entities[customerId || null].orders = orders;
    },
    getOrderType: (state, action) => {
      state.orderType = action.payload;
    },
    setOrderTypeLocation: (state, action) => {
      state.orderTypeLocation = action.payload;
    },
    resetEntities: (state, action) => {
      state.entities = [];
      state.selectedItems = [];
    },
  },
  extraReducers: {
    [getAvailableOrders.fulfilled]: shipmentOrdersAdapter.setAll,
    [getAvailableOrders.rejected]: (state, action) => {
      shipmentOrdersAdapter.removeAll(state);
      state.loading = false;
    },
    [viewShipment.fulfilled]: (state, action) => {
      state.viewShipmentData = action.payload;
      // This is for edit shipment box number for supplier
      const resData = cloneDeep(action.payload.shipment);
      const selectedOrders = [];
      resData.forEach((shipment) =>
        shipment.orders.forEach((order) =>
          {
            order.is_selected = 1;
            order.order_detail.forEach((detail) => {
              const boxNumber = detail.box_number?.split(",") || [];
              // eslint-disable-next-line
              detail.box_number_from = boxNumber[0];
              detail.box_number_to = boxNumber[boxNumber.length - 1];
              detail.is_selected = 1;
              detail.order_no= order.order_no;
            })
            selectedOrders.push(order);
          }
        )
      );
      if (resData) {
        resData.forEach((d, i) => {
          if (i) d.orders.forEach((order) => resData[0].orders.push(order));
        });
      }
      state.selectedItems = selectedOrders;
      shipmentOrdersAdapter.setAll(state, [resData[0]]);
    },
    [viewShipment.rejected]: (state) => {
      state.viewShipmentData.shipment = [];
    },

    [getOrderTypeLocations.fulfilled]: (state, action) => {
      const listOfLocations = [];
      if (action.payload?.length) {
        action.payload.forEach((item) => {
          listOfLocations.push({ name: item, value: item });
        });
      }
      state.orderTypeLocations = listOfLocations || [];
    },
  },
});

export const {
  setLoading,
  resetLoading,
  openShipmentDialog,
  closeShipmentDialog,
  openContainerDialog,
  closeContainerDialog,
  setOrdersSearchText,
  setCustomer,
  updateOrderItems,
  updateContainerOrderItems,
  updateContainerOrderItem,
  setSupplierOrderType,
  updateBoxNumber,
  getOrderType,
  setOrderTypeLocation,
  resetEntities
} = shipmentOrderSlice.actions;

export default shipmentOrderSlice.reducer;
