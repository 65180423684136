import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useFormContext, Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile, getFile } from 'app/main/apps/e-commerce/store/filesSlice';
import Icon from '@mui/material/Icon';
import FormHelperText from '@mui/material/FormHelperText';
import { IconButton, Typography } from '@mui/material';
import { HelperTooltipWithIcon } from 'app/main/apps/common/AuraToolTip';
import { getProductSubTypes, getProductPolishes, updateProductSubTypeAndPolishes } from '../../store/productsSlice';
import AuraFreeSoloControlledAutocomplete from '../../../common/AuraFreeSoloControlledAutocomplete';

function BasicInfoTab(propsParam) {
  const dispatch = useDispatch();
  const methods = useFormContext();
  const { control, formState, watch, setValue, getValues } = methods;
  const { errors } = formState;
  const { productColors, productTypes, productSubTypes, productPolishes } = useSelector(({ eCommerceApp }) => eCommerceApp.products)
  const stoneColor = watch('stone_color');
  const type = watch('type');
  const length = watch('length');
  const width = watch('width');
  const height = watch('height');
  const polish = watch('polish');
  const isReadOnly = !propsParam.isManage || propsParam.isCustomer;
  const image = watch('stone_image')
  const isLesserQuantity = propsParam?.product?.units_in_stock && propsParam?.product?.units_in_stock > Number(getValues().units_in_stock)

  function onTypeUpdate(event, value) {
    setValue('sub_type', '')
    setValue('polish', '')
    if (value?.name) {
      dispatch(getProductSubTypes({ path: '/api/productSubTypesByProductType', params: { id: value?.id, custom_product: false } }));
      dispatch(getProductPolishes({ path: '/api/polishesByProductType', params: { productTypeId: value?.id, custom_product: false } }))
    } else {
      dispatch(updateProductSubTypeAndPolishes({ data: [] }))
    }
  }

  function onStoneDataUpdate(event, value) {
    if (value && value.id && value.attachment_id) {
      dispatch(getFile(value.attachment_id)).then((response) => {
        const responseData = response ? response.payload : null;
        if (responseData) {
          const stoneColorData = { ...value, attachment: responseData.data.file };
          setValue('stone_color', stoneColorData);
        }
      });
    }
  }

  function stoneValidate() {
    return !!(stoneColor && !stoneColor.name && stoneColor.attachment && stoneColor.attachment.id);
  }

  const getPolish = () => {
    if (polish) {
      if (typeof polish === 'object') {
        return polish.inputValue || polish.name
      }
      return polish
    }
    return ''
  }

  useEffect(() => {
    if (propsParam.isManage) {
      if (length > 0 && width > 0 && height > 0 && stoneColor && type?.name) {
        const stoneColorName = stoneColor?.inputValue ?? stoneColor.name
        const typeName = type?.inputValue ?? type.name
        setValue('name', `${stoneColorName} ${typeName} ${length}x${width}x${height} ${getPolish()}`, { shouldValidate: true });
        setValue('description', `${stoneColorName} ${typeName} ${length}x${width}x${height} ${getPolish()}`, { shouldValidate: true });
      }
    }
  }, [length, width, height, polish, stoneColor, type, setValue, propsParam.isManage]);

  return (
    <div>
      <div className="flex flex-col md:flex-row w-full">
        <div className="flex w-full">
          <div className="flex flex-col w-full">
            <div className="flex flex-col md:flex-row w-full">
              <div className="flex w-full md:w-2/3">
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState }) => (
                    <div className="flex gap-10 mt-8 mb-16">
                      <Typography variant="body1" className="font-bold">
                        Name
                      </Typography>
                      <Typography variant="body1" color={fieldState.error ? 'error' : 'textSecondary'} >
                        {fieldState.error ? fieldState.error.message : field.value}
                      </Typography>
                    </div>
                  )}
                />
              </div>
              <div className="flex w-full md:w-4/7 md:ml-20">
                <div className="flex flex-col w-full mb-16">
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <div className="flex gap-10 mt-8 mb-16">
                        <Typography variant="body1" className="font-bold">
                          Description
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          {field.value}
                        </Typography>
                      </div>
                    )}
                  />
                </div>
              </div>

            </div>

            <div className="flex  w-full ">
              <div className="flex w-full md:w-5/6">
                <div className="flex flex-col w-full mb-16">
                  <AuraFreeSoloControlledAutocomplete
                    control={control}
                    controlName="stone_color"
                    inputValues={productColors}
                    labelName="Stone Color *"
                    readOnly={isReadOnly}
                    onUpdate={onStoneDataUpdate}
                    textClassName="mt-8 mb-0"
                  />
                  {stoneValidate() && <FormHelperText error>Select stone color</FormHelperText>}
                </div>
              </div>
              <div className="flex w-full md:w-5/6 md:ml-16">
                {/* <div className="flex flex-col w-full mb-16"> */}
                <Controller
                  name="length"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16"
                      id="length"
                      label="Length"
                      variant="outlined"
                      fullWidth
                      size="small"
                      type="number"
                      InputProps={{
                        readOnly: isReadOnly,
                      }}
                      required={propsParam.isManage && !isReadOnly}
                      error={!!errors.length}
                      helperText={errors?.length?.message}
                    />
                  )}
                />
                {/* </div> */}
              </div>
              <div className="flex w-full md:w-5/6  md:ml-16">
                <Controller
                  name="width"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16"
                      id="width"
                      label="Width"
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: isReadOnly,
                      }}
                      required={propsParam.isManage && !isReadOnly}
                      error={!!errors.width}
                      helperText={errors?.width?.message}
                    />
                  )}
                />
              </div>
              <div className="flex w-full md:w-5/6  md:ml-16">
                <Controller
                  name="height"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16"
                      id="height"
                      label="Height"
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: isReadOnly,
                      }}
                      required={propsParam.isManage && !isReadOnly}
                      error={!!errors.height}
                      helperText={errors?.height?.message}
                    />
                  )}
                />
              </div>

              <div className="flex w-full md:w-5/6  md:ml-16">
                <AuraFreeSoloControlledAutocomplete
                  control={control}
                  controlName="type"
                  inputValues={productTypes}
                  labelName="Type *"
                  readOnly={isReadOnly}
                  onUpdate={onTypeUpdate}
                />
              </div>
              <div className="flex w-full md:w-5/6  md:ml-16">
                {/* <Controller
                  name="polish"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16"
                      value={field.value || ''}
                      id="polish"
                      label="Polish"
                      type="text"
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: isReadOnly,
                      }}
                    />
                  )}
                /> */}
                <AuraFreeSoloControlledAutocomplete
                  control={control}
                  controlName="polish"
                  inputValues={productPolishes}
                  labelName="Polish"
                  readOnly={isReadOnly}
                />

              </div>
              <div className="flex w-full md:w-5/6  md:ml-16">
                <AuraFreeSoloControlledAutocomplete
                  control={control}
                  controlName="sub_type"
                  inputValues={productSubTypes}
                  labelName="Sub Type"
                  readOnly={isReadOnly}
                />

              </div>
            </div>
            <div className="flex  w-full">
              <div className="flex w-full md:w-2/6">
                <div className="flex flex-row w-full mb-16">
                  <Controller
                    name="units_in_stock"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mt-8 mb-16"
                        id="units_in_stock"
                        label="In Stock Qty"
                        type="number"
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputProps={{
                          readOnly: isReadOnly,
                        }}
                        error={isLesserQuantity}
                        helperText={isLesserQuantity ?
                          'Note : Reducing stock may affect existing orders. Proceed with caution.' :
                          undefined}
                      />
                    )}
                  />
                  <HelperTooltipWithIcon title="Status of a product that is currently available and ready for purchase or immediate delivery, indicating that it is on hand and not out of stock." />
                </div>
              </div>
              <div className="flex w-full md:w-2/6 md:ml-16">
                <Controller
                  name="weight"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16"
                      id="weight"
                      error={!!errors.weight}
                      required={propsParam.isManage && !isReadOnly}
                      helperText={errors?.weight?.message}
                      label="Weight"
                      variant="outlined"
                      fullWidth
                      size="small"
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">lbs</InputAdornment>
                        ),
                        readOnly: isReadOnly,
                      }}
                    />
                  )}
                />
              </div>
              <div className="flex w-full md:w-2/6  md:ml-16">
                <Controller
                  name="on_order"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16"
                      id="on_order"
                      label="On Order"
                      type="number"
                      variant="outlined"
                      fullWidth
                      size="small"
                      disabled
                    />
                  )}
                />
                <HelperTooltipWithIcon title="Products that have been requested or ordered by a customer but have not yet been delivered or received." />
              </div>
              <div className="flex w-full md:w-2/6  md:ml-16">
                <Controller
                  name="committed"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16"
                      id="committed"
                      label="Committed"
                      type="number"
                      variant="outlined"
                      fullWidth
                      size="small"
                      disabled
                    />
                  )}
                />
                <HelperTooltipWithIcon title="Quantity of a product that has been allocated or reserved for specific orders or customers, indicating that these items are no longer available for other orders or uses." />
              </div>

              <div className="flex w-full md:w-2/6  md:ml-16">
                <Controller
                  name="reorder_point"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16"
                      id="reorder_point"
                      label="Reorder Point"
                      type="number"
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: isReadOnly,
                      }}
                    />
                  )}
                />
                <HelperTooltipWithIcon title="The minimum inventory level at which a new order should be placed to ensure that stock is replenished before it runs out." />

              </div>
              <div className="flex w-full md:w-4/6  md:ml-16">
                <Controller
                  name="short_fall"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8 mb-16"
                      id="short_fall"
                      label="Short Fall"
                      type="number"
                      variant="outlined"
                      fullWidth
                      size="small"
                      disabled
                    />
                  )}
                />
                <HelperTooltipWithIcon title="Quantity of a particular item in stock is less than what is needed or expected. It indicates a deficiency or shortage in inventory levels." />
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row w-full">
        <Controller
          name="note"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-8 mb-16"
              id="note"
              label="Notes"
              type="text"
              multiline
              rows={3}
              variant="outlined"
              fullWidth
              size="small"
              InputProps={{
                readOnly: isReadOnly,
              }}
            />
          )}
        />
      </div>
      <div className="flex flex-col md:flex-row w-full">
        <div className="flex w-full">
          <AuraFreeSoloControlledAutocomplete
            control={control}
            controlName="category"
            inputValues={propsParam.categoryTypes}
            labelName="Category"
            readOnly={isReadOnly}
            className={errors && errors?.category?.message ? 'mt-8 mb-0' : 'mt-8 mb-16'}
            renderOption={(renderprops, option) => (
              <li {...renderprops} className="py-2 px-8">
                <div className="capitalize">{option.name}</div>
              </li>
            )}
            hideClearable
          />
          {errors && errors?.payment_type?.message && (
            <FormHelperText className="mt-10 text-12 text-center" error={!!errors.payment_type}>
              {errors?.payment_type?.message}
            </FormHelperText>
          )}
        </div>
        <div className="flex w-full md:w-1/8  md:ml-16">
          <Controller
            name="sku"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-8 mb-16"
                id="sku"
                label="SKU"
                type="text"
                variant="outlined"
                fullWidth
                size="small"
                InputProps={{
                  readOnly: isReadOnly,
                }}
              />
            )}
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row w-full">
        <div className="flex w-full md:w-1/8">
          <FormControlLabel
            control={
              <Controller
                name="visible_to_wholesaler"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    id="roles-id"
                    label="Visible To Wholesaler"
                    name="visible_to_wholesaler"
                    variant="outlined"
                    checked={value}
                    size="small"
                    disabled={isReadOnly}
                    onChange={(ev) => onChange(ev.target.checked)}
                  />
                )}
              />
            }
            label="Visible To Wholesaler"
          />
        </div>
      </div>
    </div>
  );
}

export default BasicInfoTab;
