import { lazy } from 'react';

const Products = lazy(() => import('./products/Products'));
const Orders = lazy(() => import('./orders/Orders'));
const RequestQuote = lazy(() => import('./order/RequestQuote'));
const Order = lazy(() => import('./order/Order'));

const AllAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'products',
      element: <Products />,
    },
    {
      path: 'quotes',
      element: <Orders type="quote" for="quotes" />,
    },
    {
      path: 'orders',
      element: <Orders type="order" for="orders" />,
    },
    {
      path: 'rfqs',
      element: <Orders type="rfq" for="rfqs" />,
    },
    {
      path: 'rfd',
      element: <Orders type="rfd" for="rfd" />,
    },
    {
      path: 'completed',
      element: <Orders type="completed" for="completed" />,
    },
    {
      path: 'invoices',
      element: <Orders type="invoice" for="invoices" />,
    },
    {
      path: 'rfqs/admin-quotes/:orderId',
      element: <Order type="rfq" />,
    },
    {
      path: 'rfqs/supplier-quotes/:orderId',
      element: <RequestQuote />,
    },
    {
      path: 'rfd/supplier-quotes/:orderId',
      element: <RequestQuote isOnlyArtDesign />,
    },
    // {
    //   path: 'rfqs/:orderId',
    //   element: <RequestQuote />,
    // },
    {
      path: 'rfqs/:orderId',
      element: <Order type="rfq" />,
    },
    {
      path: 'supplier-invoices',
      element: <Orders type="invoice" for="supplier-invoices" />,
    },
    {
      path: 'shipments',
      element: <Orders type="shipment" for="shipments" />,
    },
    {
      path: 'orders/:orderId',
      element: <Order type="order" />,
    },
    {
      path: 'rfq/:orderId',
      element: <Order type="rfq" />,
    },
    {
      path: 'rfds/:orderId',
      element: <Order type="rfq" />,
    },
    {
      path: 'quotes/:orderId',
      element: <Order type="quote" />,
    },
    {
      path: 'rfqs/request/:orderId',
      element: <Order type="rfq" />
    },
    {
      path: 'confirmed/:orderId',
      element: <Order type="order" />,
    },
    {
      path: 'completed/:orderId',
      element: <Order type="order" />,
    },
    {
      path: 'awaiting-approval/:orderId',
      element: <Order type="order" />,
    },
  ],
};

export default AllAppConfig;
